import request from '@/utils/request'

export function getLookupValues(value, id='') {

    let lookup = ''
    var go_default = false
    var endpoint = ''

    switch(value){
        case 'issue_authority':
            lookup = 'issue authority'
            break;
        case 'type_of_ownership':
            lookup = 'type of ownership'
            break;
        case 'loan_term':
            lookup = 'loan term'
            break;
        case 'civil_status':
            lookup = 'civil status'
            break;
        case 'source_of_income':
            lookup = 'source of income'
            break;
        case 'document_name':
            lookup = 'document name'
            break;
        case 'loan_purpose':
            lookup = 'loan purpose'
            break;
        case 'nationality':
            lookup = 'nationality'
            break;
        case 'nature_of_work':
            lookup = 'nature of work'
            break;
        case 'loan_type':
            lookup = 'loan type'
            break;
        case 'credit_type':
            lookup = 'credit type'
            break;
        case 'relationship':
            lookup = 'relationship'
            break;
         case 'maria-loan-type':
            lookup = 'maria-loan-type'
            break;
        case 'maria-consumption-loan-purpose':
            lookup = 'maria-consumption-loan-purpose'
            break;
        case 'maria-agriculture-loan-purpose':
            lookup = 'maria-agriculture-loan-purpose'
            break;
        case 'position':
            lookup = 'PUC Position'
            break;
        case 'deped-designations':
            lookup = 'deped-designations'
            break;
        default:
            go_default =true
            lookup = value;
            if(id) lookup += '/' + id;
    }

    if(go_default)
        endpoint = 'lookup/'
    else
        endpoint = 'lookup/values/byname/'

  return request({
    url: endpoint + lookup,
    method: 'get'
  })
}