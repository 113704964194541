<template>

    <div>

        <v-container fluid>

            <v-form ref="form">
                <v-row align="center" class="mb-4" style="border-bottom: 1px dashed; border-color: #4f2684;"> 
                    <v-subheader style="padding: 0px !important; font-size: 18px; font-weight: 400; color: #4f2684; margin-bottom: -8px;">Personal Details</v-subheader>
                </v-row>
                <v-row align="center">
                    <v-text-field
                        ref="first_name"
                        v-model="first_name"
                        :rules="[rules.required]"
                        color="csb_yellow"
                        label="First Name">
                    </v-text-field>
                </v-row>

                <v-row align="center">
                    <v-text-field
                            ref="middle_name"
                            v-model="middle_name"
                            color="csb_yellow"
                            label="Middle Name">
                    </v-text-field>
                </v-row>

                <v-row align="center">
                    <!-- <v-col cols="8" class="pr-2" style="padding: 0px;"> -->
                        <v-text-field
                            ref="last_name"
                            v-model="last_name"
                            :rules="[rules.required]"
                            color="csb_yellow"
                            label="Last Name">
                        </v-text-field>
                    <!-- </v-col> -->
                    <!-- <v-col  cols="4" style="padding: 0px;">
                        <v-text-field
                            ref="suffix"
                            v-model="suffix"
                            color="csb_yellow"
                            label="Suffix">
                        </v-text-field>
                    </v-col>       -->
                </v-row>

                <v-row align="center">
                    <v-text-field
                        ref="position_title"
                        v-model="position_title"
                        :rules="[rules.required]"
                        color="csb_yellow"
                        label="Position Title">
                    </v-text-field>
                </v-row>
                
              
                <v-row align="center">

                    <v-dialog
                        ref="dialog_birthdate"
                        v-model="modal_birthdate"
                        :return-value.sync="birthdate"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                color="csb_yellow"
                                v-model="birthdate"
                                label="Birthdate"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :rules="[rules.required]"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            color="csb_yellow"
                            ref="picker_birthdate"
                            v-model="birthdate"
                            :max="max_date"
                            min="1930-01-01"
                            @change="$refs.dialog_birthdate.save(birthdate)"
                            >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="csb_yellow"
                            @click="modal_birthdate = false, $refs.picker_birthdate.activePicker = 'YEAR'"
                        >
                            Cancel
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                    <v-col style="padding: 0px;">
                        <v-select 
                            color="csb_yellow"
                            v-model="gender" 
                            :rules="[rules.required]"
                            :items="[{display: 'Male', value: 'Male'}, {display: 'Female', value: 'Female'}]" 
                            item-text="display" 
                            item-value="value" 
                            label="Gender" 
                            clearable>
                        </v-select>
                    </v-col>
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                    <v-col style="padding: 0px;">
                        <v-select 
                            color="csb_yellow"
                            v-model="civil_status"
                            :loading="civil_status_loading"
                            :rules="[rules.required]"
                            :items="civil_status_options" 
                            item-text="display" 
                            item-value="value" 
                            label="Civil Status" 
                            clearable>
                        </v-select>
                    </v-col>
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                    <v-text-field
                        ref="mobile_no"
                        prefix="+63"
                        pattern="\d*" 
                        type="tel"
                        counter="10"
                        v-model="mobile_no"
                        :rules="[rules.required, rules.mobile_no_length, rules.mobile_no_prefix]"
                        color="csb_yellow"
                        label="Mobile No"
                        @keydown="isNumber('mobile_no')"
                        required>
                    </v-text-field>
                </v-row>

                <v-row align="center" class="mb-4" style="margin-top: -6px;">
                    <v-text-field
                        ref="email_address"
                        type="email"
                        v-model="email_address"
                        :rules="[rules.required, rules.email_address]"
                        color="csb_yellow"
                        label="Email Address"
                        required>
                    </v-text-field>
                </v-row>
            </v-form>
        </v-container>
      
        <v-row  align="center" justify="space-around" class="bottom-nav">
            <v-col class="bottom-nav-item">
                <v-btn @click="handleBack()" depressed class="bottom-nav-btn back-btn">
                    Back
                </v-btn>
            </v-col>
            <v-col class="bottom-nav-item">
                <v-btn @click="handleNext()" depressed class="bottom-nav-btn">
                    Next
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import { getLookupValues } from '@/api/profile'
import { mapGetters } from 'vuex'

const validPrefixes = [
    '905','906','915','916','917','926','927','935','936','945','953','954','955','956','963','964','965','966','967', 
    '975','976','977','994','995','996','997','817','813','900','907','908','909','910','911','912','913','914','918','919','920','921','928',
    '929','930','938','939','940','946','947','948','949','950','951','957','958','959','960','961','968','969','970','971','980','981','982','985',
    '989','992','998','999','922','923','924','925','931','932','933','934','941','942','943','944','951','952','962','971','972',

]

const mustBeValidPrefix = (value) => {
    var prefix = value.substring(0, 3);
    var res = validPrefixes.indexOf(prefix) > -1
    return res
}
   

export default {
    computed: {

        ...mapGetters([
            'addressFormIsValid'
        ]),
    },
    beforeRouteLeave(to, from, next) {

        if(to.name == 'Company' || to.name == 'Calculator' || to.name == 'CompanyDetails'){

            var profileFormIsValid = this.$refs.form.validate()
            this.$store.dispatch('user/profileFormIsValid', profileFormIsValid)
            next()
            
        } else {

            if(this.$refs.form.validate()){

                this.$store.dispatch('user/profileFormIsValid', true)

                if(to.name == 'Address'){
                    next();
         
                } else if(to.name == 'Attachment'){

                    if(this.addressFormIsValid){
                        next();
                    }
                }
                
                this.$store.dispatch('user/profile', this.$data)

            }
        } 
      
    },
  
    data: function() {
        return {
            first_name: '',
            middle_name: '',
            last_name: '',
            // suffix: '',
            birthdate: null,
            modal_birthdate: false,
            cancel_birthdate: false,
           
            civil_status: null,
            civil_status_options: [],
            gender: null,
            gender_options: [], 
            mobile_no: '',
            email_address: '',
           
            civil_status_loading: true,

            position_title: '',

            //rules
            rules: {
                required: v => !!v || 'Required.',
                mobile_no_length:  v => v.length == 10 || 'Mobile no must be 10 digits',
                mobile_no_prefix:  v => mustBeValidPrefix(v) || 'Invalid mobile no prefix',
                email_address: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Invalid email address format',
            },
        }
    },

    watch: {
        modal_birthdate (val) {
            val && setTimeout(() => (this.$refs.picker_birthdate.activePicker = 'YEAR'))
        },

    },
  
    methods:{
        handleBack(){

            this.$router.push({ path: '/calculator' })

        },
        handleNext(){

            this.$router.push({ path: '/address/index' })
        },

        fetchLookupValues(value, id=''){
            return getLookupValues(value, id).then(res => {
                return res.data.data
                
            })
        },

        

        
        isNumber: function(field){
            var charCode = (event.which) ? event.which : event.keyCode;
            
            if ((charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) && charCode !== 46 && charCode !== 188 && charCode !== 190) {
                event.preventDefault();
            } else {
                event.preventDefault();
                var valueString = event.target.value

                if(charCode==8 || charCode==46)
                    valueString = valueString.slice(0, -1)
               
                else if(field=='mobile_no'){
                    valueString = valueString + event.key
                    if(valueString.length > 10) return false
                }

                var str = valueString.replace(/[^0-9]/g,'')
                this[field] = str
            }
        },
      
    },
    created() {
        var year = new Date().getFullYear() - 18
        var month = new Date().getMonth() + 1
        var day = new Date().getDate()

        year = year.toString()
        month = month.toString()
        day = day.toString()

        this.max_date = year+'-'+month+'-'+day

        this.fetchLookupValues('civil_status').then(res => {
            this.civil_status_loading = false
            this.civil_status_options = res
        })
    },

}
</script>